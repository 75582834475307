import type { HttpResponse } from '@wix/fe-essentials-viewer-platform/http-client';
import { queryTransactions } from '@wix/ambassador-guild-bigbrother-v1-asset-transaction/http';
import { QueryTransactionsResponse } from '@wix/ambassador-guild-bigbrother-v1-asset-transaction/types';

import { queryEmployee } from '@wix/ambassador-guild-bigbrother-v1-employee/http';
import { QueryEmployeeResponse } from '@wix/ambassador-guild-bigbrother-v1-employee/types';

import { authHeaderBO } from './constants';

export const getTransactionsByEmail = async (
  flowAPI: any,
  email?: string,
): Promise<HttpResponse<QueryTransactionsResponse> | undefined> => {
  try {
    return await flowAPI.httpClient.request((context: any) => {
      const params = queryTransactions({
        query: {
          filter: {
            email,
          },
        },
      })(context);

      params.headers = {
        'Content-Type': 'application/json',
        Authorization: authHeaderBO,
      };
      return params;
    });
  } catch (err) {
    console.error('Could not fetch user email, ', err);
    return;
  }
};

export const getEmployeesByEmail = async (
  flowAPI: any,
  email?: string,
): Promise<HttpResponse<QueryEmployeeResponse> | undefined> => {
  try {
    return await flowAPI.httpClient.request((context: any) => {
      const params = queryEmployee({
        query: {
          filter: {
            email,
          },
        },
      })(context);

      params.headers = {
        'Content-Type': 'application/json',
        Authorization: authHeaderBO,
      };

      return params;
    });
  } catch (err) {
    console.error('Could not fetch user email, ', err);
    return;
  }
};
