
  import {createControllersWithDescriptors, initAppForPageWrapper} from '@wix/yoshi-flow-editor/runtime/esm/viewerScript/wrapper.js';
  
  
            
import wrapController0 from '@wix/yoshi-flow-editor-runtime/internal/viewerScript/blocks';

            import controller0 from '/home/builduser/work/fe12d179bbd238e1/packages/guild-coins-blocks/src/components/activityList/viewer.controller.ts';
            import * as _controllerExport0 from '/home/builduser/work/fe12d179bbd238e1/packages/guild-coins-blocks/src/components/activityList/model.ts';
            var controllerExport0 = _controllerExport0;
            

            
import wrapController1 from '@wix/yoshi-flow-editor-runtime/internal/viewerScript/blocks';

            import controller1 from '/home/builduser/work/fe12d179bbd238e1/packages/guild-coins-blocks/src/components/activityListFull/viewer.controller.ts';
            import * as _controllerExport1 from '/home/builduser/work/fe12d179bbd238e1/packages/guild-coins-blocks/src/components/activityListFull/model.ts';
            var controllerExport1 = _controllerExport1;
            

            
import wrapController2 from '@wix/yoshi-flow-editor-runtime/internal/viewerScript/blocks';

            import controller2 from '/home/builduser/work/fe12d179bbd238e1/packages/guild-coins-blocks/src/components/coinsList/viewer.controller.ts';
            import * as _controllerExport2 from '/home/builduser/work/fe12d179bbd238e1/packages/guild-coins-blocks/src/components/coinsList/model.ts';
            var controllerExport2 = _controllerExport2;
            

            
import wrapController3 from '@wix/yoshi-flow-editor-runtime/internal/viewerScript/blocks';

            import controller3 from '/home/builduser/work/fe12d179bbd238e1/packages/guild-coins-blocks/src/components/stats/viewer.controller.ts';
            import * as _controllerExport3 from '/home/builduser/work/fe12d179bbd238e1/packages/guild-coins-blocks/src/components/stats/model.ts';
            var controllerExport3 = _controllerExport3;
            

            
import wrapController4 from '@wix/yoshi-flow-editor-runtime/internal/viewerScript/blocks';

            import controller4 from '/home/builduser/work/fe12d179bbd238e1/packages/guild-coins-blocks/src/components/statsMini/viewer.controller.ts';
            import * as _controllerExport4 from '/home/builduser/work/fe12d179bbd238e1/packages/guild-coins-blocks/src/components/statsMini/model.ts';
            var controllerExport4 = _controllerExport4;
            

  
  var importedApp = {};


  

    import { initState, initBind } from '@wix/yoshi-flow-editor/runtime/esm/velocycleMobx';
    import { observable } from 'mobx';
    var velocycleMobx = { initState, initBind, observable };
    


  

    import { blocksControllerService } from '@wix/yoshi-flow-editor/runtime/esm/blocks/controllerService'
    

  
var createHttpClient = null;

  

  import { initI18n as initI18n } from '@wix/yoshi-flow-editor/runtime/esm/i18next/init';


  
const multilingualDisabled = false;

  

    var createExperiments = null;
    var createWidgetExperiments = null;
    


  var sentryConfig = {
      DSN: 'https://b64d739c861343bf953f330abeaf3e40@sentry.wixpress.com/3711',
      id: 'b64d739c861343bf953f330abeaf3e40',
      projectName: 'guild-coins-blocks',
      teamName: 'guild-coins',
      errorMonitor: true,
    };

  var experimentsConfig = {"centralized":true,"scopes":[]};

  var translationsConfig = {"icuEnabled":true,"defaultTranslationsPath":"/home/builduser/work/fe12d179bbd238e1/packages/guild-coins-blocks/src/assets/locales/messages_en.json","availableLanguages":["en","uk"]};

  var defaultTranslations = {"app.page.dashboard":"Dashboard","app.page.activities":"Activities"};

  var fedopsConfig = null;

  import { createVisitorBILogger as biLogger } from '/home/builduser/work/fe12d179bbd238e1/packages/guild-coins-blocks/target/generated/bi/createBILogger.ts';

  export const exports = importedApp.exports;

  export const initAppForPage = initAppForPageWrapper({
    initAppForPage: importedApp.initAppForPage,
    sentryConfig: sentryConfig,
    experimentsConfig: experimentsConfig,
    inEditor: false,
    biLogger: biLogger,
    multilingualDisabled,
    projectName: "guild-coins-blocks",
    biConfig: null,
    appName: "Wix Guild Coins",
    appDefinitionId: "dc9a3c4d-74d2-4ccf-9fa7-677b75403744",
    fedopsConfig: fedopsConfig,
    translationsConfig: translationsConfig,
    defaultTranslations: defaultTranslations,
    shouldUseEssentials: true,
    optionalDeps: {
      initI18n,
      createHttpClient,
      createExperiments,
    },
    localeDistPath: "assets/locales",
  });

  const _createControllers = createControllersWithDescriptors({
    initI18n,
    blocksControllerService,
    createHttpClient,
    createExperiments,
    velocycleMobx,
  }, [{ method: controller0,
          wrap: wrapController0,
          exports: controllerExport0,
          widgetType: "BLOCKS_WIDGET",
          translationsConfig,
          multilingualDisabled,
          experimentsConfig: {"centralized":true,"scopes":[]},
          fedopsConfig: fedopsConfig,
          sentryConfig: sentryConfig,
          persistentAcrossPages: false,
          biLogger: biLogger,
          shouldUseEssentials: true,
          withErrorBoundary: false,
          biConfig: null,
          controllerFileName: "/home/builduser/work/fe12d179bbd238e1/packages/guild-coins-blocks/src/components/activityList/viewer.controller.ts",
          appName: "Wix Guild Coins",
          appDefinitionId: "dc9a3c4d-74d2-4ccf-9fa7-677b75403744",
          projectName: "guild-coins-blocks",
          componentName: "activityList",
          localeDistPath: "assets/locales",
          defaultTranslations: defaultTranslations,
          id: "dc9a3c4d-74d2-4ccf-9fa7-677b75403744-wn2t4" }, { method: controller1,
          wrap: wrapController1,
          exports: controllerExport1,
          widgetType: "BLOCKS_WIDGET",
          translationsConfig,
          multilingualDisabled,
          experimentsConfig: {"centralized":true,"scopes":[]},
          fedopsConfig: fedopsConfig,
          sentryConfig: sentryConfig,
          persistentAcrossPages: false,
          biLogger: biLogger,
          shouldUseEssentials: true,
          withErrorBoundary: false,
          biConfig: null,
          controllerFileName: "/home/builduser/work/fe12d179bbd238e1/packages/guild-coins-blocks/src/components/activityListFull/viewer.controller.ts",
          appName: "Wix Guild Coins",
          appDefinitionId: "dc9a3c4d-74d2-4ccf-9fa7-677b75403744",
          projectName: "guild-coins-blocks",
          componentName: "activityListFull",
          localeDistPath: "assets/locales",
          defaultTranslations: defaultTranslations,
          id: "dc9a3c4d-74d2-4ccf-9fa7-677b75403744-dcei3" }, { method: controller2,
          wrap: wrapController2,
          exports: controllerExport2,
          widgetType: "BLOCKS_WIDGET",
          translationsConfig,
          multilingualDisabled,
          experimentsConfig: {"centralized":true,"scopes":[]},
          fedopsConfig: fedopsConfig,
          sentryConfig: sentryConfig,
          persistentAcrossPages: false,
          biLogger: biLogger,
          shouldUseEssentials: true,
          withErrorBoundary: false,
          biConfig: null,
          controllerFileName: "/home/builduser/work/fe12d179bbd238e1/packages/guild-coins-blocks/src/components/coinsList/viewer.controller.ts",
          appName: "Wix Guild Coins",
          appDefinitionId: "dc9a3c4d-74d2-4ccf-9fa7-677b75403744",
          projectName: "guild-coins-blocks",
          componentName: "coinsList",
          localeDistPath: "assets/locales",
          defaultTranslations: defaultTranslations,
          id: "dc9a3c4d-74d2-4ccf-9fa7-677b75403744-tpn5y" }, { method: controller3,
          wrap: wrapController3,
          exports: controllerExport3,
          widgetType: "BLOCKS_WIDGET",
          translationsConfig,
          multilingualDisabled,
          experimentsConfig: {"centralized":true,"scopes":[]},
          fedopsConfig: fedopsConfig,
          sentryConfig: sentryConfig,
          persistentAcrossPages: false,
          biLogger: biLogger,
          shouldUseEssentials: true,
          withErrorBoundary: false,
          biConfig: null,
          controllerFileName: "/home/builduser/work/fe12d179bbd238e1/packages/guild-coins-blocks/src/components/stats/viewer.controller.ts",
          appName: "Wix Guild Coins",
          appDefinitionId: "dc9a3c4d-74d2-4ccf-9fa7-677b75403744",
          projectName: "guild-coins-blocks",
          componentName: "stats",
          localeDistPath: "assets/locales",
          defaultTranslations: defaultTranslations,
          id: "dc9a3c4d-74d2-4ccf-9fa7-677b75403744-ujudi" }, { method: controller4,
          wrap: wrapController4,
          exports: controllerExport4,
          widgetType: "BLOCKS_WIDGET",
          translationsConfig,
          multilingualDisabled,
          experimentsConfig: {"centralized":true,"scopes":[]},
          fedopsConfig: fedopsConfig,
          sentryConfig: sentryConfig,
          persistentAcrossPages: false,
          biLogger: biLogger,
          shouldUseEssentials: true,
          withErrorBoundary: false,
          biConfig: null,
          controllerFileName: "/home/builduser/work/fe12d179bbd238e1/packages/guild-coins-blocks/src/components/statsMini/viewer.controller.ts",
          appName: "Wix Guild Coins",
          appDefinitionId: "dc9a3c4d-74d2-4ccf-9fa7-677b75403744",
          projectName: "guild-coins-blocks",
          componentName: "statsMini",
          localeDistPath: "assets/locales",
          defaultTranslations: defaultTranslations,
          id: "dc9a3c4d-74d2-4ccf-9fa7-677b75403744-zf24o" }],
    false);

    export const createControllers = _createControllers
