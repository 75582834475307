import model from './model';
import { Activity } from '../../types';
import { authHeaderGW, testEmail } from '../../constants';
import { getEmployeesByEmail } from '../../queries';
import mockActivities from '../../mocks/activitiesList.json';

const levelOptions = ['Beginner', 'Intermediate', 'Advanced'];

export default model.createController(({ $w, $widget, flowAPI }) => {
  return {
    pageReady: async () => {
      let repeaterData: Activity[] = [];
      let email: string;

      const getMemberData = async () => {
        const employeesResponse = await getEmployeesByEmail(flowAPI, email);
        const count = employeesResponse?.data?.metadata?.count ?? 0;

        if (count > 0) {
          await setActivityData();
          await $w('#levelSelection').expand();
          await $w('#activitiesList').expand();
          await filterActivities();
        } else {
          console.error(
            'getEmployeesByEmail::FAILED: member is not a part of any guild',
          );
          $w('#text16').text = 'Not Available';
        }
      };
      const getMember = async () => {
        try {
          email =
            await flowAPI.controllerConfig.wixCodeApi.user.currentUser.getEmail();
          await getMemberData();
        } catch (e: any) {
          console.error('getEmployeesByEmail::FAILED', e);
          $w('#text16').text = 'No Member Found';
          email = testEmail;
          getMemberData().then(() => {
            $w('#text16').text = 'Test Member Data:';
          });
        }
      };

      const setActivityData = async () => {
        $w('#levelSelection').options = levelOptions.map((option) => ({
          value: option,
          label: option,
        }));
        try {
          // @ts-expect-error
          const { _items } = await flowAPI.controllerConfig.wixCodeApi.data
            .query('@maksymn/activities/Activities')
            .find();
          $w('#activitiesList').data = _items;
          repeaterData = _items;
        } catch (err) {
          $w('#activitiesList').data = mockActivities.items;
          repeaterData = mockActivities.items;
        }
      };

      const setActivitiesList = () => {
        $w('#activitiesList').onItemReady(($item, itemData: Activity) => {
          $item('#activityTitle').text = itemData.title;
          $item('#companyName').text = itemData.company;
          $item('#activityOwner').text = itemData.owner;
          $item('#activityValue').html = itemData.value;
          $item('#activityDescription').html = itemData.description;
          $item('#activityLevel').text = itemData.difficulty?.join(', ');
          $item('#activityEnergy').text =
            itemData.price[0]?.option_value.toString();
          if (itemData.price.length > 0) {
            $item('#activityCost').options = itemData.price.map((option) => ({
              value: option.option_value.toString(),
              label: option.option_label.toString(),
            }));
          }
          $item('#activityCost').selectedIndex = 0;
          $item('#activityHeader').onClick(() => {
            if ($item('#activityDetailsWrapper').collapsed) {
              $item('#activityDetailsWrapper').expand();
              $item('#divider').hide();
              animateArrow($item, 'add');
            } else {
              $item('#activityDetailsWrapper').collapse();
              $item('#divider').show();
              animateArrow($item, 'remove');
            }
          });
          $item('#applyBtn').onClick(() => {
            requestActivity(
              itemData,
              $item('#activityCost').value,
              $item('#activityCost').label,
            );
          });
        });
      };

      const filterActivities = () => {
        $w('#levelSelection').onChange((event) => {
          if (event.target.value.length === 0) {
            $w('#activitiesList').data = repeaterData;
          } else {
            const filteredData: any[] = [];
            repeaterData.forEach((activity) => {
              activity.difficulty.forEach((level) => {
                if (event.target.value.includes(level)) {
                  filteredData.push(activity);
                }
              });
            });
            $w('#activitiesList').data = filteredData;
          }
        });
      };

      const animateArrow = ($item, mode) => {
        const { timeline } = flowAPI.controllerConfig.wixCodeApi.animations;
        const tl = timeline({ repeat: 0 });
        mode === 'add'
          ? tl
              .add($item('#readMoreActivity'), { duration: 100, rotate: 180 })
              .play()
          : tl
              .add($item('#readMoreActivity'), { duration: 100, rotate: 90 })
              .play();
      };

      const requestActivity = async (
        details: Activity,
        selectedValue,
        _selectedLabel,
      ) => {
        const activityDetails = {
          participant: email,
          activity: {
            owner: 'borysb@wix.com',
            description: details.description,
            cost: {
              domain: 'guild-coins',
              name: 'energy',
              value: selectedValue,
            },
            reward: {
              domain: 'guild-coins',
              name: 'coin',
              value: selectedValue,
            },
            durationInDays: +selectedValue,
          },
          startDate: '2022-06-20',
        };
        if (email && email?.trim() !== '') {
          try {
            const returnedTransaction = await flowAPI.httpClient.post(
              '/_serverless/guild-week-bot/activities',
              activityDetails,
              {
                headers: {
                  'Content-Type': 'application/json',
                  Authorization: authHeaderGW,
                },
              },
            );
            processTransactionSuccess(returnedTransaction);
          } catch (error) {
            console.log('ERROR IN TRANSACTION: ', error);
            processTransactionError(error);
          }
        } else {
          processTransactionError('Sorry, this is a test member');
        }
      };

      const processTransactionSuccess = (_response: any) => {
        $w('#confirmationBox').changeState('successState');
        $w('#confirmationBox').expand();
      };

      const processTransactionError = (error) => {
        $w('#errorMessage').text = error.toString();
        $w('#confirmationBox').changeState('errorState');
        $w('#confirmationBox').expand();
      };

      const closeRequestBox = () => {
        $w('#closeBtn').onClick(() => {
          $w('#confirmationBox').collapse();
        });
      };

      $widget.fireEvent('widgetLoaded', {});
      await getMember();
      setActivitiesList();
      closeRequestBox();
    },
    exports: {},
  };
});
