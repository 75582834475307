import model from './model';
import { Activity } from '../../types';
import mockDatabase from '../../mocks/activitiesList.json';

export default model.createController(({ $w, $widget, flowAPI }) => {
  return {
    pageReady: async () => {
      const getActivityData = async () => {
        try {
          // @ts-expect-error
          const { _items } = await flowAPI.controllerConfig.wixCodeApi.data
            .query('@maksymn/activities/Activities')
            .find();
          $w('#activitiesList').data = _items.slice(0, 3);
        } catch (err) {
          $w('#activitiesList').data = mockDatabase.items.slice(0, 3);
        }
        await $w('#activitiesList').expand();
      };

      const setActivitiesList = () => {
        $w('#activitiesList').onItemReady(($item, itemData: Activity) => {
          $item('#activityTitle').text = itemData.title;
          $item('#activityCompany').text = itemData.company;
          $item('#activityCost').text =
            itemData.price.length > 0
              ? itemData.price[0].option_value.toString()
              : '0';
        });
      };

      $widget.fireEvent('widgetLoaded', {});
      setActivitiesList();
      await getActivityData();
    },
    exports: {},
  };
});
