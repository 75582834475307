import model from './model';
import { getEmployeesByEmail } from '../../queries';

export default model.createController(({ $w, $widget, flowAPI }) => {
  return {
    pageReady: async () => {
      const getMemberData = async () => {
        const wixUsers = flowAPI.controllerConfig.wixCodeApi.user;
        if (wixUsers.currentUser.loggedIn) {
          try {
            const member = await wixUsers.currentUser.getEmail();
            const memberData = await getEmployeesByEmail(flowAPI, member);
            assignMemberData(memberData);
          } catch (err) {
            console.log('Could not authorize the user: ', err);

            try {
              const memberData = await getEmployeesByEmail(
                flowAPI,
                $widget.props.email,
              );

              const count = memberData?.data?.metadata?.count ?? 0;

              if (memberData && count !== null && count > 0) {
                assignMemberData(memberData?.data?.employees?.[0]);
              }
            } catch (error) {
              assignMemberData();
            }
          }
        } else {
          await wixUsers.promptLogin({ mode: 'login', modal: true });
        }
      };

      const assignMemberData = (data?) => {
        $w('#coinsStat').text = data?.assets[0].value || '0';
        $w('#energyStat').text = data.assets[1].value || '0';
      };

      $widget.fireEvent('widgetLoaded', {});
      await getMemberData();
      $widget.onPropsChanged((old, newProps) => {
        if (newProps.email) {
          getMemberData();
        }
      });
    },
    exports: {},
  };
});
