import { QueryEmployeeResponse } from '@wix/ambassador-guild-bigbrother-v1-employee/types';

import model from './model';
import { getEmployeesByEmail } from '../../queries';

export default model.createController(({ $w, $widget, flowAPI }) => {
  return {
    pageReady: async () => {
      const init = async (userEmail?: string) => {
        try {
          const employeesResponse = await getEmployeesByEmail(
            flowAPI,
            userEmail,
          );
          const employees = formatMemberData(employeesResponse?.data);
          assignDataToElements($w, employees);
        } catch (e: any) {
          console.error('getEmployeesByEmail::FAILED', e);
          assignDataToElements($w);
        }
      };
      $widget.fireEvent('widgetLoaded', {});
      let email: undefined | string;
      try {
        email =
          await flowAPI.controllerConfig.wixCodeApi.user.currentUser.getEmail();
        console.log('Email of the currently logged-in user: ', email);
      } catch (error) {
        console.log('Could not fetch member data: ', error);
      }

      await init(email);

      $widget.onPropsChanged((old, newProps) => {
        if (newProps.email) {
          init(newProps.email);
        }
      });
    },
    exports: {},
  };
});

const formatMemberData = (employeesResponse?: QueryEmployeeResponse) => {
  const isOnlyOneEmployee = employeesResponse?.metadata?.count === 1;
  const firstEmployee = employeesResponse?.employees?.[0];

  if (employeesResponse && isOnlyOneEmployee && firstEmployee) {
    return {
      firstName: firstEmployee.firstName,
      lastname: firstEmployee.lastName,
      department: firstEmployee.department,
      country: firstEmployee.country,
      coinsCount: firstEmployee.assets?.[1]?.value ?? 0,
      energyCount: firstEmployee.assets?.[0]?.value ?? 0,
    };
  }
  return employeesResponse;
};

const assignDataToElements = ($w, user?) => {
  $w('#userIntro').text = `Hello ${user?.firstName || 'Anonymous'}!`;
  $w('#coinsCount').text = user?.coinsCount ?? '0';
  $w('#energyCount').text = user?.energyCount! ?? '0';
};
